import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import StopIcon from '@material-ui/icons/Stop';
import React, { FunctionComponent } from 'react';
import AllTrackedCostsChart, {
  AllTrackedCostsChartProps,
} from '../charts/AllTrackedCostsChart';
import { dashboardMockData } from '../dashboard/dashboard-mock-data';
import {
  ContainerCard,
  ContainerCardProps,
} from '../surfaces/visualization/ContainerCard';

export interface AllTrackedCostsProps
  extends ContainerCardProps,
    AllTrackedCostsChartProps {}

const staticText = {
  title: 'All Tracked Costs',
  totalHeading: 'Total - Last 30 days',
};

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: '30px',
  },
  listItem: {
    padding: 0,
    margin: 0,
  },
}));

export const AllTrackedCosts: FunctionComponent<AllTrackedCostsProps> = ({
  data,
  ...props
}) => {
  const classes = useStyles();
  const mockData = dashboardMockData.allTrackedCosts.data.sort(
    (a, b) => b.value - a.value
  );
  const total = mockData.reduce((sum, data) => sum + data.value, 0);

  return (
    <ContainerCard {...props}>
      <h4>{staticText.title}</h4>
      <Box flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color="textSecondary">
              {staticText.totalHeading}
            </Typography>
            <Typography variant="h4">
              {`$${Number(total).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}`}
            </Typography>
            <List>
              {mockData.map((data) => (
                <ListItem key={data.id} classes={{ root: classes.listItem }}>
                  <ListItemIcon className={classes.icon}>
                    <StopIcon style={{ fill: data.color }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`$${Number(data.value).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`}
                    secondary={data.label}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} style={{ height: 275 }}>
            <AllTrackedCostsChart data={data} />
          </Grid>
        </Grid>
      </Box>
    </ContainerCard>
  );
};

export default AllTrackedCosts;
