import Box from '@material-ui/core/Box';
// also exported from '@storybook/react' if you can deal with breaking changes in 6.1
import { Meta, Story } from '@storybook/react/types-6-0';
import React from 'react';

import {
  CostTrendChart as Component,
  CostTrendChartProps as Props,
} from './CostTrendChart';

export default {
  title: 'Charts/CostTrendChart',
  component: Component,
  argTypes: {},
  decorators: [
    (Story) => (
      <Box style={{ height: 350 }}>
        <Story />
      </Box>
    ),
  ],
} as Meta;

const Template: Story<Props> = (args) => <Component {...args} />;

export const Default = Template.bind({});
export const data = [
  {
    month: 'JAN',
    amount: 60,
  },
  {
    month: 'FEB',
    amount: 66,
  },
  {
    month: 'MAR',
    amount: 44,
  },
  {
    month: 'APR',
    amount: 80,
  },
  {
    month: 'MAY',
    amount: 99,
  },
  {
    month: 'JUN',
    amount: 98,
  },
  {
    month: 'JUL',
    amount: 78,
  },
  {
    month: 'AUG',
    amount: 89,
  },
  {
    month: 'SEP',
    amount: 90,
  },
];

Default.args = {
  data,
};
