import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PaymentIcon from '@material-ui/icons/Payment';
import React, { FunctionComponent } from 'react';
import theme from '../../themes/theme-light';
import { AllTrackedCosts } from '../all-tracked-costs';
import CostTrendChart from '../charts/CostTrendChart';
import { data } from '../charts/CostTrendChart.stories';
import { CostPerProject } from '../cost-per-project/CostPerProject';
import CurrentBillSnapshot from '../current-bill-snapshot/CurrentBillSnapshot';
import dashboardMockData from '../dashboard/dashboard-mock-data';
import { ContainerCard } from '../surfaces/visualization/ContainerCard';
import InvoicesTable from '../tables/invoices-table/InvoicesTable';
import { costPerProjectData } from './billing-mock-data';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageToolbar: {
      display: 'flex',
      height: '3.5rem',
      padding: '0 1.65rem .5rem 1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.primary.main,
    },
    pageHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      fontWeight: 300,
      fontSize: '1.25rem',
    },
    pageTitle: {
      fontFamily: 'Futura, Tw Cen MT',
    },
    spc: {
      width: '1rem',
    },
    titleText: {
      color: theme.palette.text.primary,
    },
    text: {
      color: theme.palette.text.primary,
    },
  })
);

export interface BillingProps {
  customerName?: string;
  currentBill?: number;
  start?: string;
  end?: string;
}

const text = { title: 'Billing' };

export const Billing: FunctionComponent<BillingProps> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <>
      <Box className={classes.pageToolbar}>
        <div className={classes.pageHeader}>
          <PaymentIcon />
          <div className={classes.spc}></div>
          <div className={classes.pageTitle}>{text.title}</div>
        </div>
      </Box>
      <Grid container spacing={2} style={{ padding: '2em' }}>
        {/* Cost Trend */}
        <Grid item xs={12} md={6}>
          <Box flex={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.titleText} variant={'h6'}>
                  {'Cost trend'}
                </Typography>
                <Typography color={'secondary'} className={classes.text}>
                  January 2020 - September 2020
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ textAlign: 'right' }}>
                  <h4>Average monthly total cost</h4>
                  {`$${Number(50).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}`}
                </div>
              </Grid>
              <Grid item xs={12} style={{ height: '522px', width: '550px' }}>
                <CostTrendChart data={data} />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Current Bill*/}
        <Grid item xs={12} md={6} style={{ marginTop: isMobile ? '2em' : '0' }}>
          <Grid item xs={12} style={{ marginBottom: '2em' }}>
            <CurrentBillSnapshot
              customerName="Acme"
              currentBill={987}
              start="2020-09-01T00:00:00Z"
              end="2020-09-30T00:00:00Z"
              links={[
                {
                  text: 'Pay Your Bill',
                  to: 'https://ignw-test.chargebee.com/d/invoices/62',
                  external: true,
                },
                {
                  text: 'Get Support',
                  to: 'http://support.cisco.com',
                  external: true,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <AllTrackedCosts data={dashboardMockData.allTrackedCosts.data} />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <ContainerCard {...props}>
            <CostPerProject data={costPerProjectData} height={480} />
          </ContainerCard>
        </Grid>

        {/* This could be it's own invoices card component */}
        <Grid item xs={12}>
          <ContainerCard innerProps={{ p: 0 }}>
            <Grid item style={{ margin: '1em' }}>
              <Typography
                color={'secondary'}
                className={classes.titleText}
                variant={'h6'}
              >
                {'Order and invoice history'}
              </Typography>
            </Grid>
            <Grid item>
              <InvoicesTable />
            </Grid>
          </ContainerCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
